<template>
  <div class="relative flex h-screen flex-1 flex-col overflow-y-hidden bg-gray-900 font-medium text-white antialiased">
    <NavBar v-bind="auth" />
    <slot></slot>
    <ModalAddToWatchlist />
    <ModalConfirmation />
    <ModalCoinSideBar />
    <OnchainAddressesModal />
    <ChatTerminal v-if="addMessagingTerminal" />
    <NotificationBase :flash-messages="flashMessages" />
    <OnchainAddressesCreateFolderModal />
    <ModalManageWatchlistAlerts />
    <OnChainSignalDrawer />
    <ModalTOSAgreement v-if="showTOSModal" @close="showTOSModal = false" />
    <ButtonLeaveImpersonate v-if="impersonating" @click.prevent="leaveImpersonate" />
  </div>
</template>

<script setup>
import NavBar from '@/components/NavBar.vue';
import ChatTerminal from '@/components/chat/ChatTerminal.vue';
import ModalCoinSideBar from '@/components/modal/ModalCoinSideBar.vue';
import ModalAddToWatchlist from '@/components/modal/ModalAddToWatchlist.vue';
import NotificationBase from '@/components/notification/NotificationBase.vue';
import ButtonLeaveImpersonate from '@/components/ButtonLeaveImpersonate.vue';
import OnChainSignalDrawer from '@/components/drawers/OnChainSignalDrawer.vue';
import ModalManageWatchlistAlerts from '@/components/modal/ModalManageWatchlistAlerts.vue';
import OnchainAddressesModal from '@/components/onchain_addresses/OnchainAddressesModal.vue';
import OnchainAddressesCreateFolderModal from '@/components/onchain_addresses/OnchainAddressesCreateFolderModal.vue';
import ModalTOSAgreement from '@/components/modal/ModalTOSAgreement.vue';
import { useStore } from 'vuex';
import { usePage } from '@inertiajs/vue3';
import { leaveImpersonate } from '@/api/sessionApi';
import { createConsumer } from '@rails/actioncable';
import { flashMessage } from '@/composeables/helpers';
import { useWatchlistStore } from '@/stores/watchlists';
import { setupChat, unsubscribeStreamChannel } from '@/composeables/chat';
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount, onUnmounted, provide, watch } from 'vue';
import useEmitter from '@/composeables/emitter';
import debounce from 'lodash/debounce';

const page = usePage();
const $store = useStore();
const $emitter = useEmitter();
const auth = computed(() => page.props.auth);
const flashMessages = computed(() => page.props['flash-messages']);
const impersonating = computed(() => auth.value.impersonating);

//CHANNEL SUBSCRIPTIONS
const heartbeatChannel = ref(null);
const staticDataChannel = ref(null);
const cable = computed(() => {
  const host = window.location.hostname;
  const protocol = host.includes('localhost') ? 'ws://' : 'wss://';
  const port = host.includes('localhost') ? ':8080' : '';
  return createConsumer(`${protocol + host + port}/cable`);
});
const setUniversalData = response => {
  $store.commit('setUniversal', response);
};
const subscribeToHeartbeat = () => {
  return new Promise((resolve, reject) => {
    heartbeatChannel.value = cable.value.subscriptions.create('HeartbeatChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to HeartbeatChannel', new Date());
        resolve();
      },
      disconnected: function () {}
    });
  });
};
const updateLivePrices = debounce(data => $store.dispatch('updateLivePrices', data), 500);
const subscribeToUpdates = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('UpdatesChannel', {
      connected: function () {
        resolve();
      },
      disconnected: function () {},
      received: function (data) {
        if (data.payload.event == 'prices') {
          updateLivePrices(data.payload.data);
        }
      },
      rejected: function () {}
    });
  });
};
const subscribeToStaticData = () => {
  return new Promise((resolve, reject) => {
    staticDataChannel.value = cable.value.subscriptions.create('StaticDataChannel', {
      connected: function () {
        staticDataChannel.value.send({ topic: 'static_data' });
        resolve();
      },
      disconnected: function () {},
      received: function (data) {
        if (data.payload.event == 'static_data') {
          $store.dispatch('setStaticData', data.payload.data);
        }
        if (data.payload.event == 'universe_data') {
          setUniversalData(data.payload.data.universe_data);
        }
      },
      rejected: function () {
        $store.dispatch('loadCommonAppData');
      }
    });
  });
};
const subscribeToTeamNewsItems = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('TeamNewsItemsChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to subscribeToTeamNewsItems', new Date());
        resolve();
      },
      disconnected: function () {},
      received: function (event) {
        $emitter.$emit('newsItemUpdated', event);
        if (!event.team_news_item.is_starred_by_my_team) {
          return;
        }
        flashMessage(
          {
            type: 'success',
            title: 'Starred by Your Team',
            message: `${
              event.starred_by_team_member.first_name
                ? `${event.starred_by_team_member.first_name} ${event.starred_by_team_member.last_name}`
                : event.starred_by_team_member.email
            } starred "${event.news_item.translated_headline || event.news_item.headline}"`
          },
          () => {
            window.open(event.news_item.link, '_blank').focus();
          }
        );
      }
    });
  });
};
const subscribeToAdminNewsItems = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('AdminNewsItemsChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to AdminNewsItemsChannel', new Date());
        resolve();
      },
      disconnected: function () {},
      received: function (event) {
        $emitter.$emit('newsItemUpdated', event);
      }
    });
  });
};
const subscribeToAlerts = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('UserAlertsChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to UserAlertsChannel', new Date());
        resolve();
      },
      disconnected: function () {},
      received: function (event) {
        let payload = {
          title: event.alert.name,
          message: event.message
        };
        let clickEvent = null;
        if (event.url) {
          clickEvent = () => {
            if (event.open_in_new_tab) {
              window.open(event.url, '_blank');
            } else {
              window.open(event.url);
            }
          };
        }
        $store.commit('addNewUserNotification', event);
        $emitter.$emit('resetActiveLogs');
        $emitter.$emit('refreshAlertLogs');
        flashMessage(payload, clickEvent);
      }
    });
  });
};
const subscribeToUserNotifications = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('UserNotifyChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to UserNotifyChannel', new Date());
        resolve();
      },
      disconnected: function () {},
      received: function (event) {
        if (event.message) {
          let payload = {
            type: event.type || 'notice',
            message: event.message
          };
          flashMessage(payload);
        }
      }
    });
  });
};
async function subscribeChannels() {
  const socket = new WebSocket(cable.value.url);
  socket.addEventListener('error', event => {
    $store.dispatch('loadCommonAppData');
  });
  socket.addEventListener('open', () => {
    Promise.all([
      subscribeToHeartbeat(),
      subscribeToUpdates(),
      subscribeToStaticData(),
      subscribeToTeamNewsItems(),
      subscribeToAdminNewsItems(),
      subscribeToAlerts(),
      subscribeToUserNotifications()
    ]);
  });
}
onBeforeUnmount(() => {
  cable.value.subscriptions.forEach(sub => sub.unsubscribe());
});

//INITIALIZATION
const interval = ref(null);
const addMessagingTerminal = computed(() => {
  return $store.state.messagingAccess && !page.url.includes('messenger');
});
onBeforeMount(() => {
  $store.dispatch('setCoinsAndDatapoints');
  $store.commit('setApiKeysLoading', true);
  $store.commit('setStaticDataLoader', true);
  $store.dispatch('loadUserNotifications');
});
onMounted(() => {
  setupChat();
  useWatchlistStore().initialize();
  subscribeChannels();
  //WIP - need to shift to Socket as well
  interval.value = setInterval(() => {
    if ($store.getters.loggedIn) {
      $store.dispatch('updateCoins');
      staticDataChannel.value?.send({ topic: 'universe_data' });
    }
    sendHeartBeat();
  }, 60000);
  const sendHeartBeat = () => {
    if (heartbeatChannel.value) {
      heartbeatChannel.value.send({
        user_agent: navigator.userAgent,
        referer: location.href
      });
    }
  };
  window.document.onkeydown = event => {
    if (event.key === 'Escape') {
      $store.commit('setSearchMode', false);
      $store.dispatch('resetCoinSidebar');
    }
    if (event.key === '/') {
      //DON'T TRIGGER SEARCH ON INPUT
      if (event.target && ['input', 'textarea'].includes(event.target.localName)) {
        return;
      }
      $store.commit('setSearchMode', true);
    }
  };
});
onBeforeUnmount(() => {
  clearInterval(interval.value);
});
onUnmounted(() => {
  unsubscribeStreamChannel();
});

//NAV ITEMS
const navItems = ref(null);
const setNavItems = items => (navItems.value = items);
provide('navItems', navItems);
provide('setNavItems', setNavItems);

// TOS MODAL
const showTOSModal = ref(false);
const termsOfServiceStatus = computed(() => {
  return $store.state.termsOfServiceStatus;
});
watch(termsOfServiceStatus, () => {
  if (!impersonating.value && $store.state.session.user.status == 'trial' && termsOfServiceStatus.value == false)
    showTOSModal.value = true;
});

// PROVIDES
provide('impersonating', impersonating);
</script>
